import Table from "@components/layout/Table";
import { useGetBillingGroups } from "@hooks/query/useGetBillingGroups";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Label from "../Label";
import SearchInput from "../SearchInput";
import { useAppContext } from "@context/AppContext";
import { formatDate } from "@utils/formatDate";

type Level1WRSBillingGroupListProps = {
  level1Resource: any;
};

const Level1WRSBillingGroupList: React.FunctionComponent<
  Level1WRSBillingGroupListProps
> = ({ level1Resource }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const { data: billingGroups, isLoading } = useGetBillingGroups({
    params: { level1ResourceId: level1Resource.id },
  });
  const [filter, setFilter] = useState({
    name: "",
  });

  const fields = [
    {
      title: t("billing_groups.field_name"),
      name: "name",
    },
    {
      title: t("billing_groups.field_water_charges"),
      name: "waterCharges",
    },
    {
      title: t("billing_groups.created_at"),
      name: "createdAt",
    },
    {
      title: t("common.action"),
      name: "actions",
    },
  ];

  const tableData = billingGroups
    ?.filter(
      (row: { name: string }) =>
        !filter.name.length ||
        row.name
          .toString()
          .toLowerCase()
          .includes(filter.name.toString().toLowerCase()),
    )
    ?.map((bg: any) => ({
      ...bg,
      waterCharges: bg.waterCharges?.length || 0,
      createdAt: bg.createdAt ? formatDate(new Date(bg.createdAt)) : "",
      actions: (
        <Select
          placeholder={t("common.actions")}
          options={[
            ...(checkPermissions(["UpdateBillingGroups"])
              ? [
                  {
                    label: t("common.edit"),
                    value: `/polestar/billing_groups/${bg.id}/edit?level1ResourceId=${level1Resource?.id}`,
                  },
                ]
              : []),
            ...(checkPermissions(["CreateWaterCharges"])
              ? [
                  {
                    label: t("water_charges.add_water_charge"),
                    value: `/polestar/water_charges/create?level1ResourceId=${level1Resource?.id}&billingGroupId=${bg.id}`,
                  },
                ]
              : []),
          ]}
          onChange={e => navigate(e?.value ?? "")}
          menuPortalTarget={document.body}
          isSearchable={false}
          className="w-32"
        />
      ),
    }));

  return (
    <>
      <div className="flex justify-between items-end">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-3 2xl:w-2/3 relative z-20">
          <div>
            <Label htmlFor="name">{t("billing_groups.filter_name")}</Label>
            <SearchInput
              id="name"
              onChange={e => setFilter({ ...filter, name: e.target.value })}
            />
          </div>
        </form>
        {checkPermissions(["CreateBillingGroups"]) && (
          <Link
            to={`/polestar/billing_groups/create?level1ResourceId=${level1Resource?.id}`}
            className="btn-secondary text-sm"
          >
            {t("billing_groups.add_billing_group")}
          </Link>
        )}
      </div>
      <Table
        containerClassName="rounded-none md:rounded-none text-sm"
        tableHeaderClassName="relative z-10"
        fields={fields}
        data={tableData}
        loading={isLoading}
        stickyHeader
      />
    </>
  );
};

export default Level1WRSBillingGroupList;
