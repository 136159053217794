import { getWaterCharge, getWaterCharges } from "@services/waterCharge";
import { useQuery } from "@tanstack/react-query";
import { formatCurrency } from "@utils/formatCurrency";
import { formatDate } from "@utils/formatDate";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Heading from "../../layout/Heading";
import InfoPanel from "../InfoPanel";
import Legend from "../Legend";
import TextInput from "../TextInput";
import SelectOrCreateItemNo from "../SelectOrCreateItemNo";
import { useAllLevel0Resources } from "@hooks/query/useAllLevel0Resources";
import { useAllWaterClasses } from "@hooks/query/useAllWaterClasses";
import { useGetBillingGroups } from "@hooks/query/useGetBillingGroups";
import { useSearchParams } from "react-router-dom";
import SelectBillingGroup from "../SelectBillingGroup";
import Select from "react-select";
import ErrorMessage from "../ErrorMessage";

type CreateEditWaterChargeStep1Props = {
  level1Resource?: any;
  waterCharge: {
    id?: string;
    waterClasses: any[];
    billingGroup: any;
    fixedName: string;
    varName: string;
    fixedItemNo: string;
    varItemNo: string;
    fixedValue: number;
    varValue: number;
    effectiveAt: Date;
  };
  onSave: (data: {
    waterCharge: {
      id?: string;
      waterClasses: any[];
      billingGroup: any;
      fixedName: string;
      varName: string;
      fixedItemNo: string;
      varItemNo: string;
      fixedValue: number;
      varValue: number;
      effectiveAt: Date;
    };
  }) => void;
  onCancel: () => void;
};

const CreateEditWaterChargeStep1: React.FunctionComponent<
  CreateEditWaterChargeStep1Props
> = ({ level1Resource, waterCharge, onSave, onCancel }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState<{
    waterClass: string[];
    billingGroup: string[];
    effectiveAt: string[];
    fixedName: string[];
    varName: string[];
    fixedItemNo: string[];
    varItemNo: string[];
  }>({
    waterClass: [],
    billingGroup: [],
    effectiveAt: [],
    fixedName: [],
    varName: [],
    fixedItemNo: [],
    varItemNo: [],
  });
  const [successes, setSuccesses] = useState<string[]>([]);
  const [waterChargeData, setWaterChargeData] = useState<any>({
    id: waterCharge?.id,
    waterClasses: waterCharge?.waterClasses,
    billingGroup: waterCharge?.billingGroup,
    fixedName: waterCharge?.fixedName || "",
    varName: waterCharge?.varName || "",
    fixedItemNo: waterCharge?.fixedItemNo || "",
    varItemNo: waterCharge?.varItemNo || "",
    fixedValue: +formatCurrency(waterCharge?.fixedValue) || 0,
    varValue: +formatCurrency(waterCharge?.varValue) || 0,
    effectiveAt: waterCharge?.effectiveAt || new Date(),
  });

  useAllLevel0Resources({
    params: { parentId: level1Resource?.id },
    enabled: !!waterChargeData.waterClass?.level0Resource,
    onSuccess: (data: any) => {
      if (waterChargeData.waterClass?.level0Resource)
        setWaterChargeData({
          ...waterChargeData,
          level0Resource: data.find(
            (bg: any) =>
              bg.id === waterChargeData.waterClass?.level0Resource?.id,
          ),
        });
    },
  });
  const { data: waterClasses } = useAllWaterClasses({
    params: { level1ResourceId: level1Resource?.id },
    options: {
      enabled: !!waterChargeData?.level0Resource?.id,
    },
  });

  const { data: billingGroups } = useGetBillingGroups({
    options: {
      onSuccess: (data: any) => {
        const billingGroupId = searchParams.get("billingGroupId");
        if (billingGroupId)
          setWaterChargeData({
            ...waterChargeData,
            billingGroup: data.find((bg: any) => bg.id === billingGroupId),
          });
      },
    },
  });
  const { data: waterCharges } = useQuery(["water_charges"], () =>
    getWaterCharges({}),
  );
  useQuery(
    ["waterCharges", waterCharge?.id || ""],
    () => waterCharge?.id && getWaterCharge(waterCharge?.id),
    {
      refetchOnWindowFocus: false,
      enabled:
        !!waterCharge?.id &&
        !waterCharge?.fixedName &&
        !waterCharge?.fixedItemNo,
      onSuccess: data => {
        setWaterChargeData({
          ...waterChargeData,
          ...data,
          fixedValue: +formatCurrency(data?.fixedValue) || 0,
          varValue: +formatCurrency(data?.varValue) || 0,
        });
      },
    },
  );

  const getWaterClassOptions = () => {
    return waterClasses?.map((wc: any) => ({
      value: wc.id,
      label: `${wc.name} (${wc.level0Resource?.identifier})`,
    }));
  };

  const handleSave = (e: any) => {
    e.preventDefault();

    onSave({
      waterCharge: {
        ...waterChargeData,
        fixedValue: waterChargeData.fixedValue * 1000,
        varValue: waterChargeData.varValue * 1000,
      },
    });
  };

  const handleInputChange = (field: string, value: any) => {
    setWaterChargeData({ ...waterChargeData, [field]: value });
  };

  const checkInput = () => {
    const errorMessages = {
      waterClass: [],
      billingGroup: [],
      effectiveAt: [],
      fixedName: [],
      varName: [],
      fixedItemNo: [],
      varItemNo: [],
    };

    if (!validateWaterClasses(waterChargeData.waterClasses)) {
      errorMessages.waterClass.push(
        t("water_charges.warning_water_class_required"),
      );
    }
    if (!validateEffectiveAt(waterChargeData.effectiveAt)) {
      errorMessages.effectiveAt.push(
        t("water_charges.warning_effective_at_required"),
      );
    }
    if (!validateBillingGroup(waterChargeData.billingGroup)) {
      errorMessages.billingGroup.push(
        t("water_charges.warning_billing_group_required"),
      );
    }
    if (!validateWaterChargeNameRequired(waterChargeData.fixedName)) {
      errorMessages.fixedName.push(
        t("water_charges.warning_fixed_name_required"),
      );
    }
    if (!validateWaterChargeNameRequired(waterChargeData.varName)) {
      errorMessages.varName.push(t("water_charges.warning_var_name_required"));
    }
    if (!validateWaterChangeFixedNameExists(waterChargeData.fixedName)) {
      errorMessages.fixedName.push(
        t("water_charges.warning_fixed_name_exists"),
      );
    }
    if (!validateWaterChangeVarNameExists(waterChargeData.varName)) {
      errorMessages.varName.push(t("water_charges.warning_var_name_exists"));
    }
    if (!validateWaterChargeNameRequired(waterChargeData.fixedItemNo)) {
      errorMessages.fixedItemNo.push(
        t("water_charges.warning_fixed_item_no_required"),
      );
    }
    if (!validateWaterChargeNameRequired(waterChargeData.varItemNo)) {
      errorMessages.varItemNo.push(
        t("water_charges.warning_var_item_no_required"),
      );
    }

    setErrors(errorMessages);

    if (
      errorMessages.waterClass.length === 0 &&
      errorMessages.effectiveAt.length === 0 &&
      errorMessages.billingGroup.length === 0 &&
      errorMessages.fixedName.length === 0 &&
      errorMessages.varName.length === 0 &&
      errorMessages.fixedItemNo.length === 0 &&
      errorMessages.varItemNo.length === 0
    ) {
      setSuccesses([t("water_charges.success_water_charge_input_completed")]);
    } else {
      setSuccesses([]);
    }
  };

  const validateBillingGroup = (billingGroup: any) => {
    return !!billingGroup;
  };

  const validateWaterClasses = (waterClasses: any) => {
    return !!waterClasses && waterClasses.length > 0;
  };

  const validateEffectiveAt = (effectiveAt: Date) => {
    return !!effectiveAt;
  };

  const validateWaterChargeNameRequired = (name: string) => {
    return !!name.trim();
  };

  const validateWaterChangeFixedNameExists = (name: string) => {
    if (
      name &&
      waterCharges?.find(
        (wc: any) =>
          wc.id !== waterChargeData.id &&
          wc.waterClassId === waterChargeData.waterClass?.id &&
          wc.billingGroupId === waterChargeData.billingGroup?.id &&
          wc.fixedItemNo === waterChargeData.fixedItemNo &&
          formatDate(new Date(wc.effectiveAt)) ===
            formatDate(new Date(waterChargeData.effectiveAt)) &&
          wc.fixedName === name.trim(),
      )
    ) {
      return false;
    }

    return true;
  };

  const validateWaterChangeVarNameExists = (name: string) => {
    if (
      name &&
      waterCharges?.find(
        (wc: any) =>
          wc.id !== waterChargeData.id &&
          wc.waterClassId === waterChargeData.waterClass?.id &&
          wc.billingGroupId === waterChargeData.billingGroup?.id &&
          wc.varItemNo === waterChargeData.varItemNo &&
          formatDate(new Date(wc.effectiveAt)) ===
            formatDate(new Date(waterChargeData.effectiveAt)) &&
          wc.varName === name.trim(),
      )
    ) {
      return false;
    }

    return true;
  };

  const validateWaterChargeData = () => {
    if (
      !validateWaterClasses(waterChargeData.waterClasses) ||
      !validateEffectiveAt(waterChargeData.effectiveAt) ||
      !validateBillingGroup(waterChargeData.billingGroup) ||
      !validateWaterChargeNameRequired(waterChargeData.fixedName) ||
      !validateWaterChargeNameRequired(waterChargeData.varName) ||
      !validateWaterChangeFixedNameExists(waterChargeData.fixedName) ||
      !validateWaterChangeVarNameExists(waterChargeData.varName) ||
      !validateWaterChargeNameRequired(waterChargeData.fixedItemNo) ||
      !validateWaterChargeNameRequired(waterChargeData.varItemNo)
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const sessionData = sessionStorage.getItem("waterCharge");

    if (sessionData) {
      try {
        const sessionWaterCharge = JSON.parse(sessionData);

        if (sessionWaterCharge?.id === waterChargeData.id) {
          setWaterChargeData(sessionWaterCharge);

          sessionStorage.removeItem("waterCharge");
        }
      } catch {
        sessionStorage.removeItem("waterCharge");
      }
    }
  }, [waterChargeData.id]);

  return (
    <div className="flex gap-4 h-full">
      <div className="w-3/4 border bg-white h-auto">
        <form
          className="flex flex-col h-full justify-between"
          onSubmit={handleSave}
        >
          <div className="p-6 grow">
            <Heading className="text-xl leading-6" light={true}>
              {waterCharge?.id
                ? t("water_charges.edit_step1_title")
                : t("water_charges.create_step1_title")}
            </Heading>

            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <fieldset className="sm:col-span-6 flex gap-2">
                <Legend>{t("water_charges.link_to_water_class")}</Legend>
                <div className="mt-4 w-full">
                  <label htmlFor="level1WRS" className="block text-gray-600">
                    {t("water_charges.field_water_class")}
                  </label>
                  <div className="mt-1 flex-col rounded-md">
                    <Select
                      inputId="waterClassIds"
                      isMulti={true}
                      options={getWaterClassOptions()}
                      onChange={(e: any) => {
                        handleInputChange(
                          "waterClasses",
                          waterClasses.filter(
                            (bg: any) =>
                              e.findIndex((wc: any) => wc.value === bg.id) !==
                              -1,
                          ),
                        );
                      }}
                      value={getWaterClassOptions()?.filter(
                        (option: any) =>
                          waterChargeData.waterClasses.findIndex(
                            (wc: any) => wc.id === option.value,
                          ) !== -1,
                      )}
                      openMenuOnFocus
                    />
                    {errors.waterClass.length > 0 && (
                      <ErrorMessage>{errors.waterClass[0]}</ErrorMessage>
                    )}
                  </div>
                </div>
              </fieldset>

              <fieldset className="sm:col-span-6 grid grid-cols-2 gap-4">
                <Legend className="col-span-2 mb-4">
                  {t("water_charges.name_and_item_no")}
                </Legend>
                <div className="col-span-2">
                  <label htmlFor="name" className="block text-gray-600">
                    {t("water_charges.field_billing_group")}
                  </label>
                  <SelectBillingGroup
                    className="w-full"
                    level1ResourceId={level1Resource?.id}
                    onChange={(e: any) => {
                      handleInputChange(
                        "billingGroup",
                        billingGroups.find((b: any) => b.id === e.value),
                      );
                    }}
                    value={
                      waterChargeData?.billingGroup?.id ||
                      searchParams.get("billingGroupId")
                    }
                    onBlur={checkInput}
                    errorMessage={
                      errors.billingGroup.length
                        ? errors.billingGroup[0]
                        : undefined
                    }
                  />
                </div>
                <div className="col-span-1">
                  <label htmlFor="name" className="block text-gray-600">
                    {t("water_charges.field_name")} (
                    {t("water_charges.field_fixed")})
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <TextInput
                      type="text"
                      required
                      value={waterChargeData?.fixedName || ""}
                      onChange={e =>
                        handleInputChange("fixedName", e.target.value)
                      }
                      onBlur={checkInput}
                      errorMessage={
                        errors.fixedName.length
                          ? errors.fixedName[0]
                          : undefined
                      }
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label htmlFor="name" className="block text-gray-600">
                    {t("water_charges.field_name")} (
                    {t("water_charges.field_var")})
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <TextInput
                      type="text"
                      required
                      value={waterChargeData?.varName || ""}
                      onChange={e =>
                        handleInputChange("varName", e.target.value)
                      }
                      onBlur={checkInput}
                      errorMessage={
                        errors.varName.length ? errors.varName[0] : undefined
                      }
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label htmlFor="item_no" className="block text-gray-600">
                    {t("water_charges.field_item_no")} (
                    {t("water_charges.field_fixed")})
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <SelectOrCreateItemNo
                      waterClassId={waterChargeData.waterClassId}
                      part="fixedItemNo"
                      className="w-full"
                      value={waterChargeData?.fixedItemNo || ""}
                      onChange={e => handleInputChange("fixedItemNo", e.value)}
                      onBlur={checkInput}
                      errorMessage={
                        errors.fixedItemNo.length
                          ? errors.fixedItemNo[0]
                          : undefined
                      }
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label htmlFor="item_no" className="block text-gray-600">
                    {t("water_charges.field_item_no")} (
                    {t("water_charges.field_var")})
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <SelectOrCreateItemNo
                      waterClassId={waterChargeData.waterClassId}
                      part="varItemNo"
                      className="w-full"
                      value={waterChargeData?.varItemNo || ""}
                      onChange={e => handleInputChange("varItemNo", e.value)}
                      onBlur={checkInput}
                      errorMessage={
                        errors.varItemNo.length
                          ? errors.varItemNo[0]
                          : undefined
                      }
                    />
                  </div>
                </div>
              </fieldset>

              <fieldset className="sm:col-span-6 grid grid-cols-2 gap-4">
                <Legend className="col-span-2 mb-4">
                  {t("water_charges.charges")}
                </Legend>
                <div className="col-span-1">
                  <label htmlFor="value" className="block text-gray-600">
                    {t("water_charges.field_value")} (
                    {t("water_charges.field_fixed")})
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <TextInput
                      type="number"
                      required
                      value={waterChargeData?.fixedValue || 0}
                      onChange={e =>
                        handleInputChange("fixedValue", +e.target.value)
                      }
                      onBlur={checkInput}
                      step="0.01"
                      min={0}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label htmlFor="value" className="block text-gray-600">
                    {t("water_charges.field_value")} (
                    {t("water_charges.field_var")})
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <TextInput
                      type="number"
                      required
                      value={waterChargeData?.varValue || 0}
                      onChange={e =>
                        handleInputChange("varValue", +e.target.value)
                      }
                      onBlur={checkInput}
                      step="0.01"
                      min={0}
                    />
                  </div>
                </div>
                <div className="col-span-2 w-1/3">
                  <label htmlFor="value" className="block text-gray-600">
                    {t("water_charges.field_effective_at")}
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <TextInput
                      type="date"
                      value={formatDate(
                        new Date(waterChargeData?.effectiveAt),
                        "yyyy-MM-dd",
                      )}
                      onChange={e => {
                        handleInputChange(
                          "effectiveAt",
                          new Date(e.target.value),
                        );
                      }}
                      errorMessage={
                        errors.effectiveAt.length
                          ? errors.effectiveAt[0]
                          : undefined
                      }
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="border-t w-full border-gray-200 mt-5 py-4 px-6 flex gap-1">
            <button
              type="submit"
              className="btn-primary text-sm font-semibold"
              disabled={!validateWaterChargeData()}
            >
              {t("common.next_step")}
            </button>
            <button
              type="button"
              className="btn-outline-primary text-sm font-semibold"
              onClick={onCancel}
            >
              {t("common.cancel")}
            </button>
          </div>
        </form>
      </div>
      <div className="w-1/4 h-auto">
        <InfoPanel
          className="h-full"
          errors={[
            ...errors.waterClass,
            ...errors.billingGroup,
            ...errors.effectiveAt,
            ...errors.fixedName,
            ...errors.varName,
            ...errors.fixedItemNo,
            ...errors.varItemNo,
          ]}
          successes={successes}
          actions={[
            {
              label: t("billing_groups.add_billing_group"),
              action: () => {
                window.open(
                  `/polestar/billing_groups/create?level1ResourceId=${level1Resource?.id}`,
                  "_blank",
                );
              },
            },
          ]}
        >
          <div className="flex flex-col gap-5">
            {waterCharge?.id
              ? t("water_charges.edit_step1_info")
              : t("water_charges.create_step1_info")}
          </div>
        </InfoPanel>
      </div>
    </div>
  );
};

export default CreateEditWaterChargeStep1;
