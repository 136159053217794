import { useAppContext } from "@context/AppContext";
import React from "react";
import { useTranslation } from "react-i18next";

type ApplicationActionButtonsProps = {
  status: keyof ButtonsByStatus;
  onApprove?: () => void;
  onReject?: () => void;
  onRequireMoreInfo?: () => void;
  onContinue?: () => void;
  onCancel: () => void;
};

type Button = {
  label: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
};

type ButtonsByStatus = {
  1: Button[];
  2: Button[];
  3: Button[];
  4: Button[];
  6: Button[];
  7: Button[];
};

const ApplicationActionButtons: React.FunctionComponent<
  ApplicationActionButtonsProps
> = ({
  status,
  onApprove = () => {},
  onReject = () => {},
  onContinue = () => {},
  onRequireMoreInfo = () => {},
  onCancel,
}) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAppContext();
  const classNameOnApprove = "btn-primary";
  const classNameOnReject = "btn-primary bg-red-600 hover:bg-red-700";
  const classNameOnRequireInfo = "btn-default text-primary-1 border-current";
  const classNameOnCancel = "btn-primary";

  const cancelLabel = t("common.cancel");
  const completeApplicationLabel = t("common.complete_application");
  const approveLabel = t("common.approve");
  const rejectLabel = t("approval.forward_draws.approve.reject");
  const requireMoreInfoLabel = t("approval.forward_draws.approve.request_info");

  const buttonsByStatus: ButtonsByStatus = {
    1: [
      {
        label: approveLabel,
        onClick: onApprove,
        className: classNameOnApprove,
        disabled: !checkPermissions(["UpdateApprovals"]),
      },
      {
        label: rejectLabel,
        onClick: onReject,
        className: classNameOnReject,
        disabled: !checkPermissions(["UpdateApprovals"]),
      },
      {
        label: requireMoreInfoLabel,
        onClick: onRequireMoreInfo,
        className: classNameOnRequireInfo,
        disabled: !checkPermissions(["UpdateApprovals"]),
      },
      {
        label: cancelLabel,
        onClick: onCancel,
        className: classNameOnCancel,
        disabled: false,
      },
    ],
    2: [
      {
        label: cancelLabel,
        onClick: onCancel,
        className: classNameOnCancel,
        disabled: false,
      },
    ],
    3: [
      {
        label: cancelLabel,
        onClick: onCancel,
        className: classNameOnCancel,
        disabled: false,
      },
    ],
    4: [
      {
        label: approveLabel,
        onClick: onApprove,
        className: classNameOnApprove,
        disabled: !checkPermissions(["UpdateApprovals"]),
      },
      {
        label: rejectLabel,
        onClick: onReject,
        className: classNameOnReject,
        disabled: !checkPermissions(["UpdateApprovals"]),
      },
      {
        label: requireMoreInfoLabel,
        onClick: onRequireMoreInfo,
        className: classNameOnRequireInfo,
        disabled: !checkPermissions(["UpdateApprovals"]),
      },
      {
        label: cancelLabel,
        onClick: onCancel,
        className: classNameOnCancel,
        disabled: false,
      },
    ],
    6: [
      { label: cancelLabel, onClick: onCancel, className: classNameOnCancel },
    ],
    7: [
      { label: cancelLabel, onClick: onCancel, className: classNameOnCancel },
      {
        label: completeApplicationLabel,
        onClick: onContinue,
        className: classNameOnApprove,
      },
    ],
  };

  const buttons = buttonsByStatus[status] || [];

  return (
    <div className="mt-5 border-t p-4 flex flex-row-reverse gap-3 text-sm">
      {buttons.map(({ label, onClick, className, disabled }) =>
        disabled ? null : (
          <button key={label} onClick={onClick} className={className}>
            {label}
          </button>
        ),
      )}
    </div>
  );
};

export default ApplicationActionButtons;
