import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EditIcon from "../../icons/EditIcon";
import Heading from "../../layout/Heading";
import InfoPanel from "../InfoPanel";

type CreateEditBillingGroupStep2Props = {
  level1Resource?: any;
  billingGroup: {
    id?: string;
    name: string;
    level1Resource: any;
  };
  onSave: () => void;
  onEditDetails: () => void;
};

const CreateEditBillingGroupStep2: React.FunctionComponent<
  CreateEditBillingGroupStep2Props
> = ({ level1Resource, billingGroup, onSave, onEditDetails }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const handleSave = (e: any) => {
    e.preventDefault();

    onSave();
  };

  return (
    <div className="flex gap-4 h-full">
      <div className="w-3/4 border bg-white h-auto">
        <form
          className="flex flex-col h-full justify-between"
          onSubmit={handleSave}
        >
          <div className="p-6 grow">
            <Heading className="text-xl leading-6" light={true}>
              {t("billing_groups.details_completed")}
            </Heading>
            <div className="mt-6 flex flex-col gap-2">
              <div className="text-base font-bold text-gray-900">
                {t("common.details")}
              </div>
              <dl className="grid grid-cols-1 gap-x-4">
                <div className="col-1">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("billing_groups.field_level1_resource")}:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {billingGroup.level1Resource?.name}
                  </dd>
                </div>
                <div className="col-1">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("billing_groups.field_name")}:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {billingGroup.name}
                  </dd>
                </div>
                <button
                  type="button"
                  className="col-1 text-left flex gap-1 mt-2 items-center font-bold text-sm text-primary-3"
                  onClick={onEditDetails}
                >
                  <EditIcon /> {t("common.edit")}
                </button>
              </dl>
            </div>
          </div>
          <div className="border-t w-full border-gray-200 mt-5 p-6 flex gap-1">
            <button
              type="button"
              className="btn-outline-primary text-sm font-semibold"
              onClick={onEditDetails}
            >
              {t("common.prev_step")}
            </button>
            <button type="submit" className="btn-primary text-sm font-semibold">
              {t("common.ledger")}
            </button>
            <button
              type="button"
              className="btn-outline-primary text-sm font-semibold"
              onClick={() =>
                navigate(
                  redirect
                    ? redirect
                    : level1Resource
                    ? `/polestar/level1wrs/${level1Resource.id}?tab=1#3`
                    : "/polestar",
                )
              }
            >
              {t("common.cancel")}
            </button>
          </div>
        </form>
      </div>
      <div className="w-1/4 h-auto">
        <InfoPanel
          className="h-full"
          successes={[
            t("billing_groups.success_billing_group_input_completed"),
          ]}
        >
          {""}
        </InfoPanel>
      </div>
    </div>
  );
};

export default CreateEditBillingGroupStep2;
