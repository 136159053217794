import React, { useState } from "react";
import classNames from "classnames";
import { useParams, useSearchParams } from "react-router-dom";
import { startCase } from "lodash";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import Card from "@components/layout/Card";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Tag from "@components/shared/Tag";
import Loading from "@components/shared/Loading";
import { useAppContext } from "@context/AppContext";
import { useAllExtractionRights } from "@hooks/query/zenith/useAllExtractionRights";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { useAllAdministrativeApprovals } from "@hooks/query/zenith/useAllAdministrativeApprovals";
import { useGetSubscriber } from "@hooks/query/zenith/useGetSubscriber";

const ExtractionRightTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { loggedInInfo } = useAppContext();
  const { id: level0ResourceId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const [showInactiveExtractionRights, setShowInactiveExtractionRights] =
    useState(searchParams.get("isActive") === "false");
  const [showDeletedExtractionRights, setShowDeletedExtractionRights] =
    useState(searchParams.get("isDeleted") === "false");
  const [filter, setFilter] = useState({
    extractionRightName: searchParams.get("name") ?? "",
  });

  const { data: subscriber = {}, isLoading: isSubscriberLoading } =
    useGetSubscriber({
      id: loggedInInfo?.userDetails?.subscriberId,
      options: { enabled: Boolean(loggedInInfo?.userDetails?.subscriberId) },
    });

  const { data: approvals = [] } = useAllAdministrativeApprovals({
    params: {
      buyerId: loggedInInfo?.userDetails?.subscriberId,
      level0ResourceId: level0ResourceId,
      types: [5, 6],
      statuses: [2],
    },
    enabled:
      Boolean(loggedInInfo?.userDetails?.subscriberId) &&
      Boolean(level0ResourceId),
  });

  const formattedRightRelations = approvals.reduce(
    (acc: Map<string, Set<string>>, approval: any) => {
      const extractionRights = approval?.extractionRights || [];
      const parents = extractionRights
        .filter((r: any) => r.version === "1")
        .map((r: any) => r.name);
      const children = extractionRights
        .filter((r: any) => r.version !== "1")
        .map((r: any) => r.name);
      parents.forEach((parent: string) => {
        if (!acc.has(parent)) {
          acc.set(parent, new Set());
        }
        children.forEach((child: string) => acc.get(parent)!.add(child));
      });
      return acc;
    },
    new Map(),
  );

  const { data: extractionRights = [], isLoading: isExtractionRightsLoading } =
    useAllExtractionRights({
      params: {
        assertedByWalletId: subscriber.walletId,
        level0ResourceId: level0ResourceId,
        isActive:
          !showInactiveExtractionRights && !showDeletedExtractionRights
            ? true
            : false,
        isDeleted: !showDeletedExtractionRights ? false : true,
      },
      options: {
        enabled: Boolean(subscriber.walletId) && Boolean(level0ResourceId),
      },
    });

  const filteredExtractionRights = extractionRights
    ?.filter(
      (right: any) =>
        !/WH$/.test(right.name) &&
        !/SA$/.test(right.name) &&
        !/^PT-Q/.test(right.name) &&
        !right.name.includes(`Quasi-Q/${subscriber.id}`),
    )
    ?.filter(
      (right: any) =>
        filter.extractionRightName === "" ||
        right.name
          .toLowerCase()
          .includes(filter.extractionRightName.toLowerCase()),
    );

  if (isSubscriberLoading || isExtractionRightsLoading) {
    return (
      <div className="pt-20">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <header>
        <div className="flex flex-row justify-between items-end">
          <div className="max-w-sm flex-1">
            <Label htmlFor="extraction_right_name">
              {t("extraction_right.filter_extraction_right_number")}
            </Label>
            <SearchInput
              id="extraction_right_name"
              onChange={e =>
                setFilter({
                  extractionRightName: e.target.value,
                })
              }
              placeholder={t("common.search") as string}
              value={filter.extractionRightName || ""}
            />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <Switch
              checked={showInactiveExtractionRights}
              onChange={setShowInactiveExtractionRights}
              className={classNames(
                "relative inline-flex h-6 w-11 items-center rounded-full",
                showInactiveExtractionRights ? "bg-primary-1" : "bg-gray-200",
              )}
            >
              <span
                className={`${
                  showInactiveExtractionRights
                    ? "translate-x-6"
                    : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
            <span className="text-sm">
              {t("extraction_right.show_inactivated_extraction_rights")}
            </span>

            <Switch
              checked={showDeletedExtractionRights}
              onChange={setShowDeletedExtractionRights}
              className={classNames(
                "relative inline-flex h-6 w-11 items-center rounded-full",
                showDeletedExtractionRights ? "bg-primary-1" : "bg-gray-200",
              )}
            >
              <span
                className={`${
                  showDeletedExtractionRights
                    ? "translate-x-6"
                    : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
            <span className="text-sm">
              {t("extraction_right.show_deleted_extraction_rights")}
            </span>
          </div>
        </div>
      </header>

      <div className="mt-6 space-y-6">
        {filteredExtractionRights?.length ? (
          filteredExtractionRights?.map((right: any) => (
            <div key={right.id} className="pb-4 flex gap-4">
              <div className="w-3/5">
                <Card className="p-0">
                  <div className="flex flex-col">
                    <header
                      className="w-full h-auto px-4 py-2 rounded-t-lg"
                      style={{
                        backgroundColor: "#00385A",
                      }}
                    >
                      <div className="w-full flex justify-between">
                        <div className="flex justify-between items-center w-full font-light text-white text-lg">
                          <h1 className="flex items-center gap-2">
                            <span>
                              {t("extraction_right.name")} {right.name}
                            </span>
                            {right.isActive ? null : right.deletedAt ? (
                              <Tag status="error">{t("common.deleted")}</Tag>
                            ) : (
                              <Tag status="error">{t("common.inactive")}</Tag>
                            )}
                          </h1>
                          <span className="text-base">
                            {t("extraction_right.purpose")}: {right.purpose}
                          </span>
                        </div>
                      </div>
                      <div className="w-full flex"></div>
                    </header>
                    <div className="grid grid-cols-10">
                      <div className="border-r flex flex-col relative bg-gray-200 col-span-6">
                        <div className="absolute -bottom-4 rounded-b-lg px-4 h-full w-full grid bg-[#E2ECF2]">
                          <div>
                            <div className="flex justify-between">
                              <div>
                                <h3 className="text-sm">
                                  {t("extraction_right.start_at")}
                                </h3>
                                <div className="text-lg text-primary-2 font-bold">
                                  {right.startAt
                                    ? formatDate(new Date(right.startAt))
                                    : "-"}
                                </div>
                              </div>
                              <div>
                                <h3 className="text-sm">
                                  {t("extraction_right.end_at")}
                                </h3>
                                <div className="text-lg text-primary-2 font-bold">
                                  {right.endAt
                                    ? formatDate(new Date(right.endAt))
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <h3 className="text-sm">
                              {t("extraction_right.volume")}
                            </h3>
                            <div className="text-4xl text-primary-2 font-bold mt-2">
                              {formatVolume(right.volume)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-4 flex flex-col gap-2 p-4 text-sm text-gray-500">
                        <h3 className="flex justify-between">
                          {t("extraction_right.allocation_type")}
                          <span className="text-black">
                            {right.type?.identifier}
                          </span>
                        </h3>
                        <h3 className="flex justify-between">
                          {t("extraction_right.is_active")}
                          <span className="text-black">
                            {right.inActivedAt ? "I" : "A"}
                          </span>
                        </h3>
                        <h3 className="flex justify-between">
                          {t("extraction_right.source")}
                          <span className="text-black">
                            {startCase(right.level0Resource?.source)}
                          </span>
                        </h3>
                        <h3 className="flex justify-between">
                          {t("common.water_class")}
                          <span className="text-black">
                            {right.waterClass.name}
                          </span>
                        </h3>
                        {formattedRightRelations.get(right.name)?.size > 0 && (
                          <h3 className="flex justify-between">
                            {t("extraction_right.child_extraction_rights")}
                            <span className="text-black">
                              {Array.from(
                                formattedRightRelations.get(right.name) ?? [],
                              ).join(", ")}
                            </span>
                          </h3>
                        )}
                        {right.note && (
                          <h3 className="flex flex-row flex-wrap justify-between">
                            {t("common.note")}
                            <span className="text-black">
                              {right.note.note}
                            </span>
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="w-2/5">
                <Card className="p-0 w-auto">
                  <div className="flex flex-col">
                    <header className="w-auto h-auto p-4 rounded-t-lg bg-[#7ECCC2]">
                      <h1 className="font-thin text-gray-700 text-xl">
                        {t("extraction_right.billing_info")}
                      </h1>
                    </header>
                    <div className="grid grid-cols-1">
                      <div className="p-4 flex flex-col">
                        <div>
                          <h3 className="text-sm text-gray-500 grid grid-cols-2">
                            {t("extraction_right.billing_group")}
                            <span className="text-sm text-black">
                              {right.billingGroup?.name}
                            </span>
                          </h3>
                          <h3 className="mt-2 text-sm text-gray-500 grid grid-cols-2">
                            {t("extraction_right.freq")}
                            <span className="text-sm text-black">
                              {subscriber.billingFrequency}
                            </span>
                          </h3>
                          <h3 className="mt-2 text-sm text-gray-500 grid grid-cols-2">
                            {t("extraction_right.item_no")}
                            <span className="text-sm text-black">
                              {right.waterClass?.waterCharges
                                ?.filter(
                                  (wc: any) =>
                                    wc.billingGroupId === right.billingGroupId,
                                )
                                .map((i: any) => i.fixedItemNo)
                                .filter(
                                  (bg: any, i: number, self: any[]) =>
                                    self.indexOf(bg) === i,
                                )
                                .join(", ")}
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          ))
        ) : (
          <div className="py-6 whitespace-nowrap text-gray-400 text-center">
            {t("extraction_right.no_data")}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtractionRightTab;
