import { useTranslation } from "react-i18next";
import React from "react";
import Heading from "../../layout/Heading";
import InfoPanel from "../InfoPanel";
import EventTransactionPaginatedTable from "@components/table/EventTransactionPaginatedTable";
import EndButtonModal from "@components/shared/EndButton";

type CreateEditWaterChargeCompletedProps = {
  waterCharge?: {
    id: string;
    waterClasses: any;
    billingGroup: any;
    fixedName: string;
    varName: string;
    fixedItemNo: string;
    varItemNo: string;
    fixedValue: number;
    varValue: number;
    effectiveAt: Date;
  };
  workflowInstance?: any;
  onEnd: () => void;
};

const CreateEditWaterChargeCompleted: React.FunctionComponent<
  CreateEditWaterChargeCompletedProps
> = ({ waterCharge, workflowInstance }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-4 h-full">
      <div className="w-3/4 border bg-white h-auto">
        <form className="flex flex-col h-full justify-between">
          <div className="p-6 grow">
            <Heading className="text-xl leading-6" light={true}>
              {t("water_charges.workflow_completed")}
            </Heading>
            <div className="flex flex-col grow h-full">
              <EventTransactionPaginatedTable
                references={[workflowInstance?.id]}
                stickyHeader={true}
              />
            </div>
          </div>
          <div className="border-t w-full border-gray-200 mt-5 p-6 flex gap-1">
            <EndButtonModal />
          </div>
        </form>
      </div>
      <div className="w-1/4 h-auto">
        <InfoPanel
          className="h-full"
          successes={[
            t("water_charges.success_water_charge_workflow_completed", {
              name: `${waterCharge?.fixedName} (${waterCharge?.varName})`,
            }),
          ]}
        >
          {t("water_charges.success_water_charge_workflow_completed_info")}
        </InfoPanel>
      </div>
    </div>
  );
};

export default CreateEditWaterChargeCompleted;
