import { useQuery } from "@tanstack/react-query";
import {
  getAllRightFractions,
  type GetAllRightFractionsParams,
} from "@services/rightFraction";

export const useAllRightFractions = ({
  params,
  ...options
}: {
  params?: Partial<GetAllRightFractionsParams>;
  [key: string]: any;
} = {}) => {
  return useQuery<any>({
    queryKey: ["right_fractions", { params }],
    queryFn: () => getAllRightFractions(params),
    ...options,
  });
};
