import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { isArray, startCase, sumBy } from "lodash";

import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { useAllRightFractions } from "@hooks/query/useAllRightFractions";
import { extractionRightTypes } from "@services/extractionRight";

const getRemainingBalance = (
  extractionRightId: string,
  rightFractions: any,
) => {
  const filteredRightFractions = rightFractions.filter(
    (i: any) => i.extractionRightId === extractionRightId,
  );

  return sumBy(filteredRightFractions, (i: any) => +i.balance);
};

type ExtractionRightTableProps = {
  subscriber: any;
  onSelect: any;
  selected: any;
  showRemainingH2W?: boolean;
  multiple?: boolean;
};

const ExtractionRightTable: React.FunctionComponent<
  ExtractionRightTableProps
> = ({
  subscriber,
  onSelect,
  selected,
  showRemainingH2W = true,
  multiple = true,
}) => {
  const { t } = useTranslation();
  const { data: extractionRights = [] } = useAllExtractionRights({
    params: {
      assertedByWalletId: subscriber?.walletId,
      types: [extractionRightTypes.WA, extractionRightTypes.WSA],
      isActive: true,
    },
    options: {
      enabled: Boolean(subscriber?.walletId),
    },
  });

  const { data: rightFractions = [] } = useAllRightFractions({
    params: {
      extractionRightIds: extractionRights.map((i: any) => i?.id),
      isLinked: true,
    },
    enabled: extractionRights.length > 0 && showRemainingH2W,
  });

  const [filter, setFilter] = React.useState("");
  const tableFields = [
    {
      title: t("extraction_right.name"),
      name: "name",
    },
    {
      title: t("common.level0wrs"),
      name: "level0ResourceIdentifier",
    },
    {
      title: t("extraction_right.allocation_type"),
      name: "allocationType",
    },
    {
      title: t("extraction_right.source"),
      name: "source",
    },
    {
      title: t("common.water_class"),
      name: "waterClassName",
    },
    {
      title: t("extraction_right.volume"),
      name: "formatVolume",
    },
    {
      title: t("extraction_right.h2w_balance"),
      name: "formatRemainingBalance",
    },
  ];

  const tableData = extractionRights.map((right: any) => {
    const remainingBalance = showRemainingH2W
      ? getRemainingBalance(right.id, rightFractions)
      : 0;

    return {
      id: right.id,
      level0ResourceId: right.level0ResourceId,
      allocationType: right?.type?.identifier,
      name: right?.name,
      source: startCase(right?.level0Resource?.source),
      formatVolume: formatVolume(right?.volume),
      volume: right?.volume,
      waterClassName: right?.waterClass?.name,
      level0ResourceIdentifier: right?.level0Resource?.identifier,
      startAt: right?.startAt ? formatDate(right.startAt) : "-",
      endAt: right?.endAt ? formatDate(right.endAt) : "-",
      billingGroup: right?.billingGroup?.name,
      cumulativeAllocated: right?.cumulativeAllocated ?? 0,
      ...(showRemainingH2W && {
        remainingBalance,
        formatRemainingBalance: formatVolume(remainingBalance),
      }),
      extractionPoints: [],
    };
  });

  const filteredTableData = tableData.filter((row: any) =>
    row.name?.toString().includes(filter),
  );

  const handleSelect = useCallback(
    (values: string[]) => {
      const selected = values.map(selectedId =>
        tableData.find(({ id }: any) => id === selectedId),
      );
      onSelect(selected);
    },
    [onSelect, tableData],
  );

  React.useEffect(() => {
    if (!isArray(selected) && extractionRights?.length) {
      handleSelect(extractionRights.map((i: any) => i.id));
    }
  }, [selected, extractionRights, handleSelect]);

  return (
    <>
      <div className="p-6 flex flex-col gap-4 grow">
        <Heading light>
          {t("permanent_trade.selected_name", {
            name: subscriber.name,
          })}
        </Heading>

        <form className="max-w-xs">
          <Label htmlFor="customer-id">
            {t("extraction_right.filter_extraction_right_number")}
          </Label>
          <SearchInput onChange={e => setFilter(e.target.value)} />
        </form>

        <Table
          fields={tableFields}
          data={filteredTableData}
          selectionKey="id"
          selectedKeys={selected?.map((i: any) => i?.id)}
          onSelectionChange={handleSelect}
          stickyHeader
          multiple={multiple}
        />
      </div>
    </>
  );
};

export default ExtractionRightTable;
