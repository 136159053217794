import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import TabPanels from "@components/form/TabPanels";
import InfoPanel from "@components/form/InfoPanel";
import Level1WRSBillingGroupList from "./Level1WRSBillingGroupList";
import Level1WRSWaterChargeList from "./Level1WRSWaterChargeList";

type Level1WRSBillingProps = {
  level1Resource: any;
};

const Level1WRSBilling: React.FunctionComponent<Level1WRSBillingProps> = ({
  level1Resource,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || 0;

  return (
    <div className="flex grow gap-4">
      <div className="grow">
        <TabPanels
          className="bg-white border"
          selectedTab={+tab || 0}
          tabs={[
            {
              title: t("common.water_charges"),
              panel: (
                <Level1WRSWaterChargeList level1Resource={level1Resource} />
              ),
              tabInfoPanel: (
                <InfoPanel className="h-full">
                  <ul className="flex flex-col gap-5 list-disc list-outside">
                    <li>
                      <Trans
                        i18nKey="billing_groups.charge_list_1"
                        values={{
                          schemeName: level1Resource?.name,
                        }}
                      />
                    </li>
                    <li>{t("billing_groups.charge_list_2")}</li>
                    <li>{t("billing_groups.charge_list_3")}</li>
                  </ul>
                </InfoPanel>
              ),
            },
            {
              title: `${t("common.billing_groups")}`,
              panel: (
                <Level1WRSBillingGroupList level1Resource={level1Resource} />
              ),
              tabInfoPanel: (
                <InfoPanel className="h-full">
                  <ul className="flex flex-col gap-5 list-disc list-outside">
                    <li>
                      <Trans
                        i18nKey="billing_groups.billing_group_1"
                        values={{
                          schemeName: level1Resource?.name,
                        }}
                      />
                    </li>
                    <li>{t("billing_groups.billing_group_2")}</li>
                    <li>{t("billing_groups.billing_group_3")}</li>
                  </ul>
                </InfoPanel>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Level1WRSBilling;
