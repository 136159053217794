import React from "react";
import { sumBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useStep, UseStepHelpers } from "@hooks/useStep";
import { useValidateExtractionRightName } from "@hooks/mutation/useValidateExtractionRightName";
import { useAllWaterClasses } from "@hooks/query/useAllWaterClasses";
import {
  GetInfoMessagesFunction,
  ModifyStatusesFunction,
  useStepStatuses,
} from "@hooks/useStepStatuses";
import {
  AdministrativeApprovalNumericType,
  ApprovalExtractionRightVersion,
  ExtractionRightApprovalType,
} from "@services/administrativeApprovals";
import { toastError } from "@utils/toast";
import { formatDate } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { type ConfirmData } from "@components/shared/ConfirmationDetail";

type Details = {
  id: string;
  subscriber: any;
  level1wrs: { id: string; name: string; identifier: string };
  level0wrs: {
    id: string;
    identifier: string;
    yield: number;
    hasClasses: boolean;
    source: string;
  };
  waterClass: { id: string; name: string };
  linkToExtractionPoints: [
    { level0wrsId: string; id: string; name: string; isActive: boolean },
  ];
  volume: number;
  eventRelatedModalIds: Array<string>;
  rightFractions: Array<any>;
  description: string;
  reference: string;
  name: string;
  billingGroup: any;
  endAt?: Date;
  startAt?: Date;
  activatedEvidenceIds: string[];
  isActive: boolean;
};

type HandleChangeDetails = (
  key: keyof Details,
  value: any,
  subKey?: string,
) => void;

type Info = Record<
  "level1Resource" | "subscriber" | "extractionRight" | "extractionPoints",
  ConfirmData
>;

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  details: Details;
  setDetails: React.Dispatch<React.SetStateAction<Details>>;
  handleChangeDetails: HandleChangeDetails;
  workflowCompleted: boolean;
  setWorkflowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<React.SetStateAction<any>>;
  fromSubscriber: any;
  setFromSubscriber: React.Dispatch<React.SetStateAction<any>>;
  navigateForCancel: () => void;
  fromLevel0wrs: any;
  setFromLevel0wrs: React.Dispatch<React.SetStateAction<any>>;
  checkHasOutOfLevel0WRSRequest: () => boolean;
  checkHasInactivePointToLink: () => boolean;
  validateName: (name: string) => Promise<void>;
  networkErrors: string[];
  setNetworkErrors: React.Dispatch<React.SetStateAction<string[]>>;
  eventRelatedModalIds: string[];
  setEventRelatedModalIds: React.Dispatch<React.SetStateAction<string[]>>;
  updatingRight: any;
  setUpdatingRight: React.Dispatch<React.SetStateAction<any>>;
  totalWaterClassesVolume: number;
  waterClasses: any;
  getInfoMessages: GetInfoMessagesFunction;
  modifyStatuses: ModifyStatusesFunction;
  amalgamateOrSubdivideDetails?: AmalgamateOrSubdivideDetails;
  setAmalgamateOrSubdivideDetails: React.Dispatch<
    React.SetStateAction<AmalgamateOrSubdivideDetails>
  >;
  translationContext?: "subdivide" | "amalgamate";
  remainingVolumeForSubdivide: number;
  info: Info;
};

const CreateOrUpdateExtractionRightContext = React.createContext<
  ContextValue | undefined
>(undefined);

const initialDetails: Details = {
  id: "",
  subscriber: {
    id: "",
    name: "",
    accountNumber: "",
  },
  level1wrs: { id: "", name: "", identifier: "" },
  level0wrs: {
    id: "",
    identifier: "",
    yield: 0,
    hasClasses: false,
    source: "",
  },
  waterClass: { id: "", name: "" },
  linkToExtractionPoints: [
    { level0wrsId: "", id: "", name: "", isActive: true },
  ],
  volume: 0,
  eventRelatedModalIds: [],
  rightFractions: [],
  description: "",
  reference: "",
  name: "",
  billingGroup: null,
  activatedEvidenceIds: [],
  isActive: true,
};

type AmalgamateOrSubdivideDetails = Record<string, any>;

const CreateOrUpdateExtractionRightProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const maxStep = 6;
  const [currentStep, stepHelpers] = useStep(maxStep);
  const { getInfoMessages, modifyStatuses } = useStepStatuses(maxStep);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isActive = searchParams.get("isActive") ?? "";
  const [updatingRight, setUpdatingRight] = React.useState<any>();
  const [details, setDetails] = React.useState(initialDetails);
  const [workflowCompleted, setWorkflowCompleted] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();
  const [fromSubscriber, setFromSubscriber] = React.useState<any>();
  const [fromLevel0wrs, setFromLevel0wrs] = React.useState<any>();
  const [networkErrors, setNetworkErrors] = React.useState<string[]>([]);
  const [eventRelatedModalIds, setEventRelatedModalIds] = React.useState<
    string[]
  >([]);
  const [amalgamateOrSubdivideDetails, setAmalgamateOrSubdivideDetails] =
    React.useState<AmalgamateOrSubdivideDetails>();
  const { mutateAsync: checkExtractionRightNameMutation } =
    useValidateExtractionRightName();

  const { data: waterClasses = [] } = useAllWaterClasses({
    params: {
      level0ResourceId: details?.level0wrs?.id,
    },
    enabled: Boolean(details?.level0wrs?.id),
    select: (data: any[]) => {
      return data.filter(i => i.level0ResourceId === details?.level0wrs?.id);
    },
  });

  React.useEffect(() => {
    if (isActive) {
      setDetails(prev => ({
        ...prev,
        isActive: isActive === "true" ? true : false,
      }));
    }
  }, [isActive]);

  const totalWaterClassesVolume = waterClasses.length
    ? sumBy(waterClasses, (i: any) => +i.volume)
    : 0;

  const handleChangeDetails: HandleChangeDetails = (key, value, subKey) => {
    setDetails(prevState => {
      const updatedDetails: any = { ...prevState };
      if (subKey) {
        updatedDetails[key] = { ...prevState[key], [subKey]: value };
      } else {
        updatedDetails[key] = value;
      }
      return updatedDetails;
    });
  };

  const navigateForCancel = () => {
    const returnString = fromSubscriber
      ? `/polestar/subscribers/${fromSubscriber?.id}?level0ResourceId=${
          fromLevel0wrs ? fromLevel0wrs.id : undefined
        }&extractionRightId=random`
      : `/polestar/level1wrs/${details?.level1wrs.id}#4`;
    navigate(returnString);
  };

  const checkHasOutOfLevel0WRSRequest = (): boolean => {
    let result = false;
    for (let i = 0; i < details.linkToExtractionPoints.length; i++) {
      const extractionPointLevel0wrsId =
        details.linkToExtractionPoints[i].level0wrsId;
      if (
        extractionPointLevel0wrsId !== "" &&
        extractionPointLevel0wrsId !== details.level0wrs.id
      ) {
        result = true;
        break;
      }
    }
    return result;
  };

  const checkHasInactivePointToLink = (): boolean => {
    let result = false;
    for (let i = 0; i < details.linkToExtractionPoints.length; i++) {
      const active = !details.linkToExtractionPoints[i].isActive;
      if (active) {
        result = true;
        break;
      }
    }
    return result;
  };

  const validateName = async (name: string) => {
    if (name.trim() === "") {
      modifyStatuses({
        stepNumber: 1,
        fieldName: "name",
      });
      return;
    }
    if (name.trim() === updatingRight?.name) {
      modifyStatuses({
        stepNumber: 1,
        fieldName: "name",
      });
      return;
    }

    try {
      const exists = await checkExtractionRightNameMutation({
        name: name.trim(),
      });

      if (exists) {
        modifyStatuses({
          stepNumber: 1,
          fieldName: "name",
          message: t(
            "extraction_right.create.step_2.validate_name.error_duplicate_name",
          ) as string,
          infoType: "error",
        });
      } else {
        modifyStatuses({
          stepNumber: 1,
          fieldName: "name",
          message: t(
            "extraction_right.create.step_2.validate_name.success_duplicate_name",
          ) as string,
          infoType: "success",
        });
      }
    } catch (error) {
      toastError(
        t("extraction_right.create.step_2.validate_name.network_failure"),
      );
    }
  };

  const translationContext =
    amalgamateOrSubdivideDetails &&
    (amalgamateOrSubdivideDetails.type === AdministrativeApprovalNumericType.AME
      ? ExtractionRightApprovalType.Amalgamate
      : amalgamateOrSubdivideDetails.type ===
        AdministrativeApprovalNumericType.SDE
      ? ExtractionRightApprovalType.Subdivide
      : undefined);

  const calculateRemainingVolumeForSubdivide = (details: any) => {
    if (!details || !details.extractionRights) {
      return 0;
    }

    const newRightsVolume = sumBy(
      details.extractionRights.filter(
        (er: any) => +er.version === ApprovalExtractionRightVersion.New,
      ),
      "volume",
    );

    const oldRightsVolume = sumBy(
      details.extractionRights.filter(
        (er: any) => +er.version === ApprovalExtractionRightVersion.Old,
      ),
      "volume",
    );

    return oldRightsVolume - newRightsVolume;
  };

  const remainingVolumeForSubdivide = calculateRemainingVolumeForSubdivide(
    amalgamateOrSubdivideDetails,
  );

  const info: Info = {
    level1Resource: {
      title: t("common.level1wrs"),
      body: [
        {
          key: t("common.name"),
          value: `${details.level1wrs?.name} (${details.level1wrs?.identifier})`,
        },
      ],
      disableEdit: true,
    },
    subscriber: {
      title: t("subscriber.create.subscriber_details"),
      body: [
        {
          key: t("common.name"),
          value: `${details.subscriber?.name} (${details.subscriber?.accountNumber})`,
        },
      ],
      onChain: updatingRight || amalgamateOrSubdivideDetails ? true : false,
    },
    extractionRight: {
      title: t("extraction_right.details"),
      body: [
        {
          key: t("common.name"),
          value: details?.name,
        },
        {
          key: t("common.level0wrs"),
          value: details.level0wrs?.identifier,
        },
        {
          key: t("common.water_class"),
          value: details.waterClass?.name,
        },
        {
          key: t("common.description"),
          value: details.description,
        },
        {
          key: t("extraction_right.volume"),
          value: formatVolume(+details.volume * 1000000),
        },
        {
          key: t("common.issued"),
          value: details.startAt ? formatDate(details.startAt) : "-",
        },
        {
          key: t("common.lapses"),
          value: details.endAt ? formatDate(details.endAt) : "-",
        },
        {
          key: t("extraction_right.create.step_2.billing_group"),
          value: details.billingGroup?.name,
        },
      ],
    },
    extractionPoints: {
      title: t("extraction_right.create.step_4.link_extraction_points"),
      body: [
        {
          key: t("common.extraction_points"),
          value:
            details?.linkToExtractionPoints.length > 0
              ? details?.linkToExtractionPoints.map(ep => ep.name).join(", ")
              : t("extraction_right.create.step_4.no_points_to_link"),
        },
      ],
    },
  };
  const value: ContextValue = {
    currentStep,
    stepHelpers,
    details,
    setDetails,
    handleChangeDetails,
    workflowCompleted,
    setWorkflowCompleted,
    workflowInstance,
    setWorkflowInstance,
    fromSubscriber,
    setFromSubscriber,
    navigateForCancel,
    fromLevel0wrs,
    setFromLevel0wrs,
    checkHasOutOfLevel0WRSRequest,
    checkHasInactivePointToLink,
    validateName,
    networkErrors,
    setNetworkErrors,
    eventRelatedModalIds,
    setEventRelatedModalIds,
    updatingRight,
    setUpdatingRight,
    totalWaterClassesVolume,
    waterClasses,
    getInfoMessages,
    modifyStatuses,
    amalgamateOrSubdivideDetails,
    setAmalgamateOrSubdivideDetails,
    translationContext,
    remainingVolumeForSubdivide,
    info,
  };

  return (
    <CreateOrUpdateExtractionRightContext.Provider value={value}>
      {children}
    </CreateOrUpdateExtractionRightContext.Provider>
  );
};

const useCreateOrUpdateExtractionRightContext = () => {
  const context = React.useContext(CreateOrUpdateExtractionRightContext);
  if (context === undefined) {
    throw new Error(
      "useCreateOrUpdateExtractionRightContext must be used within a CreateOrUpdateExtractionRightProvider",
    );
  }
  return context;
};

export {
  CreateOrUpdateExtractionRightProvider,
  useCreateOrUpdateExtractionRightContext,
};
