import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from "react";

type BillingGroupData = {
  billingGroup: {
    id: string;
    level1Resource: any;
    name: string;
  };
};

type WaterChargeData = {
  waterCharge: {
    id: string;
    level0Resource: any;
    waterClasses: any;
    billingGroup: any;
    fixedName: string;
    varName: string;
    fixedItemNo: string;
    varItemNo: string;
    fixedValue: number;
    varValue: number;
    effectiveAt: Date;
  };
};

type ContextValue = {
  billingGroupData: BillingGroupData;
  setBillingGroupData: (data: BillingGroupData) => void;
  waterChargeData: WaterChargeData;
  setWaterChargeData: (data: WaterChargeData) => void;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
};

const BillingAndChargeContext = createContext<ContextValue>({} as ContextValue);

const BillingAndChargeProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [billingGroupData, setBillingGroupData] = useState<BillingGroupData>({
    billingGroup: {
      name: "",
      level1Resource: null,
    },
  } as BillingGroupData);
  const [waterChargeData, setWaterChargeData] = useState<WaterChargeData>({
    waterCharge: {
      waterClasses: [],
      billingGroup: null,
      fixedName: "",
      varName: "",
      fixedItemNo: "",
      varItemNo: "",
      fixedValue: 0,
      varValue: 0,
      effectiveAt: new Date(),
    },
  } as WaterChargeData);
  const [workflowInstance, setWorkflowInstance] = useState<any>();

  return (
    <BillingAndChargeContext.Provider
      value={{
        billingGroupData,
        setBillingGroupData,
        waterChargeData,
        setWaterChargeData,
        workflowInstance,
        setWorkflowInstance,
      }}
    >
      {children}
    </BillingAndChargeContext.Provider>
  );
};

function useBillingAndChargeContext() {
  const context = useContext(BillingAndChargeContext);
  if (context === undefined) {
    throw new Error(
      "useBillingAndChargeContext must be used within a BillingAndChargeProvider",
    );
  }
  return context;
}

export { BillingAndChargeProvider, useBillingAndChargeContext };
