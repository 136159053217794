import api from "./api";

export const checkLogIn = async (loginDetails: {
  email: string;
  password: string;
}): Promise<{ user: any; token: string }> => {
  const { data } = await api.post("/checkLogin", loginDetails);

  return data;
};

export const sendCode = async (loginDetails: {
  email: string;
  password: string;
  mfaMethod: string;
}) => {
  const { data } = await api.post("/sendCode", loginDetails);

  return data;
};

export const verifyCode = async (loginDetails: {
  email: string;
  password: string;
  mfaMethod: string;
  code: string;
}) => {
  const { data } = await api.post("/verifyCode", loginDetails);

  return data;
};

export const resetPassword = async (loginDetails: { email: string }) => {
  const { data } = await api.post("/resetPassword", loginDetails);

  return data;
};

export const resetPasswordConfirm = async (loginDetails: {
  email: string;
  password: string;
  code: string;
}) => {
  const { data } = await api.post("/resetPasswordConfirm", loginDetails);

  return data;
};

export const refreshToken = async () => {
  const { data } = await api.get("/refreshToken");

  return data;
};

export const logout = async (logoutDetails: { id: string }) => {
  const { data } = await api.post("/logout", logoutDetails);

  return data;
};

export const signUp = async (payload: {
  subscriber: Record<string, any>;
  address: Record<string, any>;
  user: Record<string, any>;
}) => {
  const { data } = await api.post("/signUp", payload);

  return data.user;
};

export const confirmSignUp = async (payload: {
  email: string;
  confirmationCode: string;
}) => {
  const { data } = await api.post("/confirmSignUp", payload);

  return data;
};

export const resendConfirmSignUpCode = async (payload: { email: string }) => {
  const { data } = await api.post("/resendConfirmSignupCode", payload);

  return data;
};

export const getAuthMode = async () => {
  const { data } = await api.get("/authMode");

  return data;
};

export const ssoGetTokens = async (payload: { code: string }) => {
  const { data } = await api.post("/sso/getTokens", payload);

  return data;
};
