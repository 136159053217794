import { useAllLevel1Resources } from "@hooks/query/useAllLevel1Resources";
import { useGetBillingGroups } from "@hooks/query/useGetBillingGroups";
import { getBillingGroup } from "@services/billingGroup";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Heading from "../../layout/Heading";
import InfoPanel from "../InfoPanel";
import SelectLevel1Resource from "../SelectLevel1Resource";
import TextInput from "../TextInput";

type CreateEditBillingGroupStep1Props = {
  level1Resource?: any;
  billingGroup: {
    id?: string;
    name: string;
    level1Resource: any;
  };
  onSave: (data: {
    billingGroup: {
      id?: string;
      name: string;
      level1Resource: any;
    };
  }) => void;
};

const CreateEditBillingGroupStep1: React.FunctionComponent<
  CreateEditBillingGroupStep1Props
> = ({ level1Resource, billingGroup, onSave }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const [errors, setErrors] = useState<{
    level1Resource: string[];
    name: string[];
  }>({
    level1Resource: [],
    name: [],
  });
  const [successes, setSuccesses] = useState<string[]>([]);
  const { data: level1Resources } = useAllLevel1Resources();
  const [billingGroupData, setBillingGroupData] = useState<any>({
    id: billingGroup?.id,
    name: billingGroup?.name || "",
    level1Resource: billingGroup?.level1Resource || level1Resource,
  });
  const { data: billingGroups } = useGetBillingGroups();
  useQuery(
    ["billingGroup", billingGroup?.id || ""],
    () => billingGroup?.id && getBillingGroup(billingGroup?.id),
    {
      refetchOnWindowFocus: false,
      enabled:
        !!billingGroup?.id &&
        !billingGroup?.name &&
        !billingGroup?.level1Resource,
      onSuccess: data => {
        const billingGroup = {
          ...data,
          level1Resource: data.level1WRS,
        };

        setBillingGroupData({ ...billingGroupData, ...billingGroup });
      },
    },
  );

  const handleSave = (e: any) => {
    e.preventDefault();

    onSave({ billingGroup: billingGroupData });
  };

  const handleInputChange = (field: string, value: any) => {
    setBillingGroupData({ ...billingGroupData, [field]: value });
  };

  const checkInput = () => {
    const errorMessages = {
      level1Resource: [],
      name: [],
    };

    if (!validateLevel1Resource(billingGroupData.level1Resource)) {
      errorMessages.level1Resource.push(
        t("billing_groups.warning_level1_resource_required"),
      );
    }
    if (!validateBillingGroupNameRequired(billingGroupData.name)) {
      errorMessages.name.push(
        t("billing_groups.warning_billing_group_name_required"),
      );
    }
    if (!validateBillingGroupNameExists(billingGroupData.name)) {
      errorMessages.name.push(
        t("billing_groups.warning_billing_group_name_exists"),
      );
    }

    setErrors(errorMessages);

    if (
      errorMessages.level1Resource.length === 0 &&
      errorMessages.name.length === 0
    ) {
      setSuccesses([t("billing_groups.success_billing_group_input_completed")]);
    } else {
      setSuccesses([]);
    }
  };

  const validateBillingGroupNameRequired = (name: string) => {
    return !!name.trim();
  };

  const validateBillingGroupNameExists = (name: string) => {
    if (
      name &&
      billingGroups?.find(
        (bg: any) =>
          bg.id !== billingGroupData.id &&
          bg.level1ResourceId === billingGroupData.level1Resource?.id &&
          bg.name === name.trim(),
      )
    ) {
      return false;
    }

    return true;
  };

  const validateLevel1Resource = (level1Resource: any) => {
    return !!level1Resource;
  };

  const validateBillingGroupData = () => {
    if (
      !validateLevel1Resource(billingGroupData.level1Resource) ||
      !validateBillingGroupNameRequired(billingGroupData.name) ||
      !validateBillingGroupNameExists(billingGroupData.name)
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="flex gap-4 h-full">
      <div className="w-3/4 border bg-white h-auto">
        <form
          className="flex flex-col h-full justify-between"
          onSubmit={handleSave}
        >
          <div className="p-6 grow">
            <Heading className="text-xl leading-6" light={true}>
              {billingGroup?.id
                ? t("billing_groups.edit_step1_title")
                : t("billing_groups.create_step1_title")}
            </Heading>

            <div className="mt-6 flex flex-col gap-4">
              <div className="w-1/2">
                <label htmlFor="level1WRS" className="block text-gray-600">
                  {t("billing_groups.field_level1_resource")}
                </label>
                <div className="mt-1 flex rounded-md">
                  <SelectLevel1Resource
                    className="w-full"
                    onChange={(e: any) =>
                      handleInputChange(
                        "level1Resource",
                        level1Resources?.find(
                          (scheme: any) => scheme.id === e?.value,
                        ),
                      )
                    }
                    value={billingGroupData.level1Resource?.id}
                    onBlur={checkInput}
                    errorMessage={
                      errors.level1Resource.length
                        ? errors.level1Resource[0]
                        : undefined
                    }
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label htmlFor="name" className="block text-gray-600">
                  {t("billing_groups.field_name")}
                </label>
                <div className="mt-1 flex rounded-md">
                  <TextInput
                    type="text"
                    required
                    value={billingGroupData?.name || ""}
                    onChange={e => handleInputChange("name", e.target.value)}
                    onBlur={checkInput}
                    errorMessage={
                      errors.name.length ? errors.name[0] : undefined
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border-t w-full border-gray-200 mt-5 py-4 px-6 flex gap-1">
            <button
              type="submit"
              className="btn-primary text-sm font-semibold"
              disabled={!validateBillingGroupData()}
            >
              {t("common.next_step")}
            </button>
            <button
              type="button"
              className="btn-outline-primary text-sm font-semibold"
              onClick={() =>
                navigate(
                  redirect
                    ? redirect
                    : level1Resource
                    ? `/polestar/level1wrs/${level1Resource.id}?tab=1#3`
                    : "/polestar",
                )
              }
            >
              {t("common.cancel")}
            </button>
          </div>
        </form>
      </div>
      <div className="w-1/4 h-auto">
        <InfoPanel
          className="h-full"
          errors={[...errors.level1Resource, ...errors.name]}
          successes={successes}
          actions={
            billingGroup?.id
              ? [
                  {
                    label: t("water_charges.add_water_charge"),
                    action: () => {
                      window.open(
                        `/polestar/water_charges/create?level1ResourceId=${level1Resource?.id}&billingGroupId=${billingGroup?.id}`,
                        "_blank",
                      );
                    },
                  },
                ]
              : []
          }
        >
          <div className="flex flex-col gap-5">
            {billingGroup?.id
              ? t("billing_groups.edit_step1_info")
              : t("billing_groups.create_step1_info")}
          </div>
        </InfoPanel>
      </div>
    </div>
  );
};

export default CreateEditBillingGroupStep1;
