import React from "react";
import CreatableSelect from "react-select/creatable";
import { noop } from "lodash";

import ErrorMessage from "@components/form/ErrorMessage";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";
import { useTranslation } from "react-i18next";
import { ExtractionPointType } from "@services/extractionPoints";

type SelectExtractionPointGroupProps = {
  level1ResourceId: string;
  id?: string;
  value?: string;
  className?: string;
  onChange?: (value: any) => void;
  onBlur?: any;
  errorMessage?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
};

const SelectExtractionPointGroup: React.FunctionComponent<
  SelectExtractionPointGroupProps
> = ({
  level1ResourceId,
  id,
  value,
  className,
  onChange = noop,
  onBlur = noop,
  errorMessage,
  isClearable = true,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const { data: options = [], isLoading } = useAllExtractionPoints({
    params: { level1ResourceId, type: ExtractionPointType.Regular },
    options: {
      select: (data: any) => {
        if (!level1ResourceId) return [];

        return data
          .map((wc: any) => ({
            label: wc.group,
            value: wc.group,
          }))
          .filter(
            (item: any, i: number, self: any[]) =>
              self.findIndex(n => n.value === item.value) === i,
          )
          .filter((item: any) => item.label)
          .sort((a: any, b: any) => a.value.localeCompare(b.value));
      },
      enabled: Boolean(level1ResourceId),
    },
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <CreatableSelect
        id={id}
        options={options}
        value={options.find((i: any) => i.value === value)}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        isClearable={isClearable}
        placeholder={t("meter.adding_meter.select_or_create")}
        isDisabled={isDisabled}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default SelectExtractionPointGroup;
