import { formatCurrency } from "@utils/formatCurrency";
import { formatDate } from "@utils/formatDate";
import { useTranslation } from "react-i18next";
import React from "react";
import EditIcon from "../../icons/EditIcon";
import Heading from "../../layout/Heading";
import InfoPanel from "../InfoPanel";

type CreateEditWaterChargeStep2Props = {
  level1Resource?: any;
  waterCharge: {
    id?: string;
    waterClasses: any;
    billingGroup: any;
    fixedName: string;
    varName: string;
    fixedItemNo: string;
    varItemNo: string;
    fixedValue: number;
    varValue: number;
    effectiveAt: Date;
  };
  onSave: () => void;
  onEditDetails: () => void;
  onCancel: () => void;
};

const CreateEditWaterChargeStep2: React.FunctionComponent<
  CreateEditWaterChargeStep2Props
> = ({ waterCharge, onSave, onEditDetails, onCancel }) => {
  const { t } = useTranslation();
  const handleSave = (e: any) => {
    e.preventDefault();

    onSave();
  };

  return (
    <div className="flex gap-4 h-full">
      <div className="w-3/4 border bg-white h-auto">
        <form
          className="flex flex-col h-full justify-between"
          onSubmit={handleSave}
        >
          <div className="p-6 grow">
            <Heading className="text-xl leading-6" light={true}>
              {t("water_charges.details_completed")}
            </Heading>
            <div className="mt-6 flex flex-col gap-2">
              <div className="text-base font-bold text-gray-900">
                {t("common.details")}
              </div>
              <dl className="grid grid-cols-2 gap-x-4">
                <div className="col-span-2">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("water_charges.field_water_class")}:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {waterCharge.waterClasses
                      ?.map(
                        (wc: any) =>
                          `${wc.name} (${wc.level0Resource?.identifier})`,
                      )
                      .join(", ")}
                  </dd>
                </div>
                <div className="col-span-2">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("water_charges.field_billing_group")}:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {waterCharge.billingGroup.name}
                  </dd>
                </div>
                <div className="col-1">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("water_charges.field_name")} (
                    {t("water_charges.field_fixed")}):
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {waterCharge.fixedName}
                  </dd>
                </div>
                <div className="col-1">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("water_charges.field_name")} (
                    {t("water_charges.field_var")}):
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {waterCharge.varName}
                  </dd>
                </div>
                <div className="col-1">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("water_charges.field_item_no")} (
                    {t("water_charges.field_fixed")}):
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {waterCharge.fixedItemNo}
                  </dd>
                </div>
                <div className="col-1">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("water_charges.field_item_no")} (
                    {t("water_charges.field_var")}):
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {waterCharge.varItemNo}
                  </dd>
                </div>
                <div className="col-1">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("water_charges.field_value")} (
                    {t("water_charges.field_fixed")}):
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {formatCurrency(waterCharge.fixedValue)}
                  </dd>
                </div>
                <div className="col-1">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("water_charges.field_value")} (
                    {t("water_charges.field_var")}):
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {formatCurrency(waterCharge.varValue)}
                  </dd>
                </div>
                <div className="col-span-2">
                  <dt className="text-sm font-bold text-gray-500">
                    {t("water_charges.field_effective_at")}:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-500">
                    {formatDate(new Date(waterCharge.effectiveAt))}
                  </dd>
                </div>
                <button
                  type="button"
                  className="col-1 text-left flex gap-1 mt-2 items-center font-bold text-sm text-primary-3"
                  onClick={onEditDetails}
                >
                  <EditIcon /> {t("common.edit")}
                </button>
              </dl>
            </div>
          </div>
          <div className="border-t w-full border-gray-200 mt-5 p-6 flex gap-1">
            <button
              type="button"
              className="btn-outline-primary text-sm font-semibold"
              onClick={onEditDetails}
            >
              {t("common.prev_step")}
            </button>
            <button type="submit" className="btn-primary text-sm font-semibold">
              {t("common.ledger")}
            </button>
            <button
              type="button"
              className="btn-outline-primary text-sm font-semibold"
              onClick={onCancel}
            >
              {t("common.cancel")}
            </button>
          </div>
        </form>
      </div>
      <div className="w-1/4 h-auto">
        <InfoPanel
          className="h-full"
          successes={[t("water_charges.success_water_charge_input_completed")]}
        >
          {""}
        </InfoPanel>
      </div>
    </div>
  );
};

export default CreateEditWaterChargeStep2;
