import api from "./api";

export type GetAllRightFractionsParams = {
  extractionPointIds: string[];
  extractionRightIds: string[];
  accountNumber: string;
  isLinked: boolean;
};

export const getAllRightFractions = async (
  params?: Partial<GetAllRightFractionsParams>,
) => {
  const { data } = await api.get("/admin/rightFractions", { params });

  return data.rightFractions ?? [];
};
