import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import ErrorMessage from "@components/form/ErrorMessage";
import { useAllExtractionPoints } from "@hooks/query/useAllExtractionPoints";
import Tag from "@components/shared/Tag";
import { ExtractionPointType } from "@services/extractionPoints";

type SelectProps = React.ComponentProps<Select>;

type SelectExtractionPointProps = Exclude<SelectProps, "value"> & {
  value: string;
  errorMessage?: string;
  level1ResourceId?: string;
  level0ResourceId?: string;
  definedByWalletId?: string;
  isActive?: boolean;
};

const SelectExtractionPoint: React.FunctionComponent<
  SelectExtractionPointProps
> = ({
  errorMessage,
  value,
  level1ResourceId,
  level0ResourceId,
  definedByWalletId,
  isActive,
  ...props
}) => {
  const { t } = useTranslation();
  const { data: options = [] } = useAllExtractionPoints({
    params: {
      level1ResourceId,
      level0ResourceId,
      definedByWalletId,
      isActive,
      type: ExtractionPointType.Regular,
    },
    options: {
      select: (data: any) => {
        return data.map((item: any) => {
          const hasMeter = item?.meter !== null;
          return {
            label: (
              <div className="flex justify-between">
                <div>{item.name}</div>
                <div className="flex gap-2">
                  <Tag status={item.isActive ? "success" : "warning"}>
                    {item.isActive ? t("common.active") : t("common.inactive")}
                  </Tag>
                  <Tag status={hasMeter ? "info" : "error"}>
                    {hasMeter ? t("common.meter") : t("meter.unmetered")}
                  </Tag>
                </div>
              </div>
            ),
            value: item.name,
            meter: item.meter,
            id: item.id,
            level0ResourceId: item.level0ResourceId,
            subscriberId: item.subscriber?.id,
          };
        });
      },
      enabled: !props.isDisabled,
    },
  });

  return (
    <>
      <Select
        options={options}
        value={
          value
            ? options.find((i: any) => i.value === value || i.id === value)
            : null
        }
        {...props}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default SelectExtractionPoint;
